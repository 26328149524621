import './index.css'
import logo from "../../assets/images/logo.png"
import curr from "../../assets/images/cur.png"
import img from "../../assets/images/1.png"
import line from "../../assets/images/line.png"
import earth from "../../assets/images/earth.png"
import DropDown from './dropdown'
import { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import { AiOutlineCloseCircle } from 'react-icons/ai'

function Navbar() {
    const [open, setOpen] = useState(true)
    const [subMwnu, setSubMwnu] = useState(false)

    const navigate = useNavigate()
    const location = useLocation();
    const menuItems = [
        ['Earn', ['Einstein Mine', 'Wonder Engine', 'Partner Bonding (coming soon)', ' Bond Rewards (coming soon)', ' Stake (coming soon)']],
        ['Lend', ['Deposit (coming soon)', 'Borrow (coming soon)', 'Withdraw (coming soon)', 'Repay (coming soon)', 'Liquidity (coming soon)']],
        ['Trade', ['$EMC Dex', 'Liquidity (coming soon)', 'Galactic Auction (coming soon)', 'Trade Stake (coming soon)', 'Partner Bonding (coming soon)']],
        ['...', ['Launch (coming soon)', 'User Dash (coming soon)', 'Governance (coming soon)', 'Dex Info (coming soon)', 'Auction Info (coming soon)', 'Roadmap (coming soon)', 'Docs']]]

    useEffect(() => {
        console.log('location{', location.pathname, '}')
        if (location.pathname == '/Einstein-Mine') {
            setSubMwnu(true)
        }
        else {
            setSubMwnu(false)


        }
    }, [location.pathname])

    return (
        <>
            {/* Mobile navigation Start */}
            {/* top navigation */}
            <div className='mobile_navbar'>
                <div className='m_left'>
                    <div className='logo_cnt' onClick={() => navigate("/", { replace: false })}>
                        <img src={logo} alt="" />
                        <span>8th Wonder</span>
                    </div>
                </div>
                <div className='m_right'>
                    <span className='lang_container'>
                        <img src={earth} alt="" />
                    </span>
                    <div className='nav_button_container'>
                        <button className='theme_button'>
                            Connect Wallet
                        </button>
                    </div>
                </div>
            </div>
            {/* bottom navigation */}

            <div className='mobile_navigation_bottom'>
                {menuItems.map((v, i) => {
                    return < DropDown position='top' key={i} MenuItem={v} />
                })}
            </div>
            {/* Mobile navigation end */}

            {/* Warning box for desktop and mobile both start */}
            {
                open ?
                    <div className='top_warning_container'>
                        <p><span style={{ color: '#FF0000', fontWeight: 700 }}>PHISHING WARNING: </span> please make sure you're visiting <span style={{ fontWeight: 700 }}>https://8thwonder.com</span> - check the URL carefully</p>
                        <span onClick={() => setOpen(false)} className='close_wrapper'><AiOutlineCloseCircle /></span>
                    </div>
                    :
                    null
            }
            {/* Warning box for desktop and mobile both end */}


            {/* desktop navigation start */}
            <div className={subMwnu ? 'navbar_wrapper' : 'sdf'} >
                <div className='navbar_container'>
                    <div className='n_left_cnt'>
                        <div className='logo_cnt' onClick={() => navigate("/", { replace: false })}>
                            <img src={logo} alt="" />
                            <span>8th Wonder</span>
                        </div>
                        <div className='menu_cnt'>
                            {menuItems.map((v, i) => {
                                return < DropDown position='down' key={i} MenuItem={v} />
                            })}
                        </div>

                    </div>
                    <div className='n_right_cnt'>
                        <div className='currency_wrapper'>
                            <img src={curr} alt="" />
                            <span>$00.0</span>
                        </div>
                        <div className='audit_container'>
                            <img src={img} alt="" />
                        </div>
                        <span className='lang_container'>
                            <img src={earth} alt="" />
                        </span>
                        <div className='nav_button_container'>
                            <button className='theme_button'>
                                Connect Wallet
                            </button>
                        </div>
                    </div>
                </div>
                <div className='line_wrapper'>
                    <img src={line} alt="" />
                </div>
                {/*  for earn page sub menu */}
                {
                    subMwnu ?
                        <div className='second_menu_section'>
                            <div className='active_page'> Einstein Mine</div>
                            <div> Wonder Engine</div>
                            <div> Partner Bonding (coming soon)</div>
                            <div> Bond Rewards (coming soon)</div>
                            <div> Stake (coming soon)</div>
                        </div>
                        :
                        null
                }
            </div>
            {/* desktop navigation end */}

        </>
    )
}

export default Navbar;