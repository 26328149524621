import './index.css'
import { FaTwitter, FaTelegramPlane } from 'react-icons/fa';
import { BsDiscord, BsYoutube } from 'react-icons/bs';
import line from '../../assets/images/f_line.png'
import logo from "../../assets/images/logo.png"
import React, { useContext, useState } from "react";
import light from "../../assets/images/2.png"
import dark from "../../assets/images/3.png"
import { ThemeContext } from "../../App";
import { useNavigate, useLocation } from 'react-router-dom'

function Footer() {
    const { darkMode, setDarkMode } = useContext(ThemeContext);
    const theme = darkMode ? "light" : "dark";
    const navigate = useNavigate()
    const circleClickHandler = (theme) => {
        setDarkMode(!darkMode);
        document.documentElement.className = theme;
    };
    return (
        <div className='footer_container'>
            <div className='logo_cnt' onClick={() => navigate("/", { replace: false })}>
                <img src={logo} alt="" />
                <span>8th Wonder</span>
            </div>
            <div className='icons_container'>
                <span onClick={() => window.open(" https://twitter.com/8thWonderDefi", "_blank")}><FaTwitter /></span>
                <span><BsDiscord /></span>
                <span onClick={() => window.open(" https://t.me/EighthWonderProtocol", "_blank")}><FaTelegramPlane /></span>
                <span><BsYoutube /></span>
            </div>
            <div className='footer_line'>
                <img src={line} alt="" />
            </div>
            <div className='theme_btn_wrapper'>
                <div onClick={() => circleClickHandler(theme)} className='theme_container'>
                    <img src={darkMode ? dark : light} alt="" />
                </div>
            </div>
        </div >
    )
}

export default Footer;