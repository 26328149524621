import './index.css'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Circle({ perc }) {
    const percentage = perc;
    return (
        <CircularProgressbar className='circular_bar' background={true} value={percentage} text={`${percentage}%`} />
    )

}

export default Circle;