import Navbar from '../components/navbar/index';
import Cover from '../components/cover';
import Presale from '../components/presale_section';
import { useEffect } from "react";
import Referral from '../components/referrals_section';
import { useLocation } from 'react-router-dom';
import bottom_design from '../assets/images/bottom_design.png'

function Home(props) {

  useEffect(() => {
    document.title = '8th Wonder | Home'
  }, [])


  return (
    <Cover />
  );
}

export default Home;
