import Presale from '../components/presale_section';
import Referral from '../components/referrals_section';
import LabAssistant from '../components/lab_assitant';
import { useEffect } from 'react';

function PresalePage() {

    useEffect(() => {
        document.title = '8th Wonder | Einstein Mine'
    }, [])

    return (
        <div className='presale_page_body'>
            <Presale />
            <LabAssistant />
            <Referral />
        </div>
    );
}

export default PresalePage;
