import './index.css'
import icon from '../../assets/images/round.png'
import icon2 from '../../assets/images/round_dark.png'
import bin from '../../assets/images/bnb.png'
import { ThemeContext } from '../../App'
import { useContext } from "react";
import { useState, useEffect } from 'react';
import Countdown from "react-countdown";

const renderer3 = ({ hours, days }) => {
  return (
    <h1>Cool Down: {days} Days {hours} Hours</h1>
  );
}

function TimeDecay() {
  const [decay, setDecay] = useState(0)
  const [date_, setDate_] = useState((new Date));
  var date_from_backend = new Date(2022, 4, 28, 0, 47, 5, 5); // you can also pass date in milli seconds
  var diff = Math.floor((date_ - date_from_backend) / (1000 * 60 * 60 * 24));

  useEffect(() => {
    setDecay((0.10 * diff))
  }, [])

  useEffect(() => {

    setTimeout(() => {
      if (diff > 0) {
        setDecay((0.10 * diff))
      }
      else {
      }
      setDate_(new Date)
    }, 3600000);
  }, [date_])

  return <h1>Time Decay: <span>{decay.toFixed(2)}%</span></h1>
}

function LabAssistant() {
  const { darkMode } = useContext(ThemeContext);

  return (
    <div className='lab_container_wrapper'>

      <div className='lab_container'>
        <div className='l_input_box'>
          <div className='first_wrapper'>
            <h1>Lab Assistants: <span className='blueColor'>XXX</span></h1>
            <h1>Formulas: <span>XXX</span></h1>
          </div>

          <p><img src={darkMode ? icon2 : icon} alt='' /> 80% Sales Tax:<span>&nbsp; Active</span> </p>
          <div className='input_wrapper'>
            <div className='input_wrapper_div_input'>
              <input className='number-wrapper' type="number"></input><span className='input_bnb'><img src={bin} alt="" /></span>
            </div>
            <div className='input_wrapper_div_button'>
              <button className='theme_button'>Hire Assistants</button>
            </div>
          </div>
          <div className='l_botton_buttons'>
            <button className='theme_button'>Hire More Assistants</button>
            <button className='theme_button'>Sell Formulas</button>
            <button className='theme_button'>Push To Presale</button>
          </div>
        </div>

        <div className='l_bottom_heading'>
          {/* <h1>Cool Down: 14 Days 0 Hours</h1> */}
          <Countdown date={Date.now() + 1209600000} renderer={renderer3} />
          <TimeDecay />
          {/* <h1>Time Decay: <span>00.00%</span></h1> */}
        </div>
      </div >

    </div >
  )
}

export default LabAssistant;