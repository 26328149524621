import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import './index.css'
import { useNavigate } from "react-router-dom";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        ref={ref}
        className='singleMenuItem'
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </div>
));



export default function DropDown({ position, MenuItem }) {
    const navigate = useNavigate();
    return (
        <>
            <Dropdown drop={position} className="TopMenuItems">
                <Dropdown.Toggle as={CustomToggle}>{MenuItem[0]}</Dropdown.Toggle>
                <Dropdown.Menu className='menu-box-dropdown'>
                    {
                        MenuItem[1].map((v, i) => {
                            if (v === 'Einstein Mine') {
                                return <Dropdown.Item onClick={() => navigate("/Einstein-Mine", { replace: true })} key={i} eventKey={i + 1}>{v}</Dropdown.Item>

                            }
                            else {
                                return <Dropdown.Item key={i} eventKey={i + 1}>{v}</Dropdown.Item>
                            }
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>


        </>
    );
}