import './index.css'

import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Circle from './progress'
import Countdown from "react-countdown";
import { LineChart } from './line_chart'

const renderer2 = ({ hours, days }) => {
    return (
        <span>{days * 24 + hours} Hrs</span>
    );
}

function Presale() {

    return (
        <div className='presale_wrapper'>
            <div className='learn_more'> <Link to={"#"}>Learn More</Link> <MdKeyboardArrowRight /></div>

            <div className='presale_container'>
                <div className='presale_content' >
                    <h1>Einstein Mine</h1>
                    <p>Einstein Mine is a Play 2 Earn ROI Dapp that operates similar to a perpetual bonding protocol. The
                        contract is designed to support core development initiatives and incentivize BNB treasury inflows. This is
                        primarily accomplished by allowing investors to purchase $EMC Governance tokens over time at a
                        discount market rate while providing a return on their principal deposit. Visit our instructions for more
                        information</p>
                </div>
                <div className='row_wrapper'>
                    <div className='sub_container'>
                        <div className='content_wrapp'>
                            <p>Distribution Pool Pecent</p>
                            <span>%.0001</span>
                            <br /><br />
                            <p>Monthly Allocation Pool</p>
                            <span>$EMC</span>
                        </div>
                        <div className='row_image'>
                            <Circle perc={2} />

                        </div>
                    </div>
                    <div className='sub_container'>
                        <div className='content_wrapp'>
                            <p>Compount Count</p>
                            <span>000</span>
                            <br /><br />
                            <p>Rewards Full In</p>
                            <Countdown date={Date.now() + 12999900} renderer={renderer2} />
                            {/* <span>48 Hrs</span> */}
                        </div>
                        <div className='row_image'>
                            <Circle perc={47} />
                        </div>
                    </div>
                </div>
                <div className='row_wrapper'>
                    <div className='sub_container'>
                        <div className='content_wrapp'>
                            <p>Total Value Locked</p>
                            <span>870K TVL</span>
                            <br /><br />
                            <p>TVL Goal</p>
                            <span>1M TVL</span>
                        </div>
                        <div className='row_image'>
                            <Circle perc={87} />

                        </div>
                    </div>
                    <div className='sub_container'>
                        <div className='content_wrapp'>
                            <p>Contract Balance</p>
                            <span>0 BNB</span>
                            <br /><br />
                            <p>Total Staked</p>
                            <span>0.53 BNB</span>
                        </div>
                        <div className='row_image line_graph_container'>
                            <LineChart />
                        </div>
                    </div>
                </div>
                <div className='deposits_cnt'>
                    <p>Initial Deposit <br /><span>0 BNB</span></p>
                    <p>Total Deposit<br /><span>0 BNB</span></p>
                    <p>Withdrawin Amont <br /><span>0 BNB</span></p>
                    <p>Referral Count<br /><span>0 BNB</span></p>
                </div>
            </div>
        </div >

    )
}

export default Presale;