import './index.css'
import { ImArrowRight2 } from 'react-icons/im';
import img from "../../assets/images/animate.mov"
import React, { useContext } from "react";
import Countdown from "react-countdown";
import planet from '../../assets/images/Planet.png'

const renderer = ({ days, hours, minutes, seconds }) => {

    return (
        <div className='contdown_wrapper'>
            <div className='countdown_text'>
                {String(days).padStart(2, '0')}<span>d</span>
            </div>

            <div className='countdown_text'>
                {String(hours).padStart(2, '0')}<span>h</span>
            </div>
            <div className='countdown_text'>
                {String(minutes).padStart(2, '0')}<span>m</span>
            </div>

            <div className='countdown_text '>
                {String(seconds).padStart(2, '0')}<span>s</span>
            </div>
        </div>
    );
}

function Cover() {
    var date = new Date("Sat July 30 14:00:00 EST 2022")

    return (
        <div className='main_cover_wrapper'>
            <div className='countdown_wrapper_main'>
                <div>
                    <Countdown date={date} renderer={renderer} />
                    <button className='theme_button'>Start Mining <span><ImArrowRight2 /></span> </button>
                </div>
                <div className='planet_image_wrapper'>
                    <img src={planet} alt="" />
                </div>
            </div>

            <div className='main_cover_container'>
                <div className='content_container_cover'>
                    <div className='cover_content'>
                        <h1>8th Wonder<br /> Defi With A <br />Difference</h1>
                        <p>The World’s First Socially Distributed Defi Protocol </p>
                        <div className='cover_button'>
                            <div>
                                <button className='theme_button'>Connect Wallet</button>
                            </div>
                            <div className='theme2_button'>
                                <button className='theme_button'>Trade Now !</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='design_container'>
                    <video playsInline loop={true} autoPlay={true} muted={true}>
                        <source src={img} />
                    </video>
                </div>
            </div>
        </div>
    )
}

export default Cover;