import AppRouter from './config/router';
import React, { useState, createContext, useEffect } from "react";
export const ThemeContext = createContext();

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const darkModeTheme = {
    darkMode,
    setDarkMode,
  };
  return (
    <ThemeContext.Provider value={darkModeTheme}>
      <div className='root_container'>
        <AppRouter />
      </div>
    </ThemeContext.Provider>

  );
}

export default App;
