import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import Home from "../pages/home";
import PresalePage from "../pages/perpectual_presale";

function AppRouter() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Einstein-Mine" element={<PresalePage />} />
                <Route path="*" element={<Home />} />
            </Routes>
            <Footer />
        </Router>
    )
}

export default AppRouter;