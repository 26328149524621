import './index.css'


function Referral() {
  return (
    <div className='referral_container_wrapper'>

      <div className='referral_container'>
        <div className='theme_heading'>
          <h1>Referrals :</h1>
        </div>
        <div className='referral_text'>
          <p>Your referral link will appear here once you connect your wallet</p>
          <button className='theme_button'>Copy Link</button>
        </div>
      </div >
    </div >
  )
}

export default Referral;