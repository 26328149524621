import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ThemeContext } from '../../../App'
import { useContext } from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            ticks: {
                display: false,
            },
            grid: {
                display: false,
                lineWidth: [0.5],
                color: '#DAD3D3',


            }
        },
        x: {
            ticks: {
                display: false,
            },
            grid: {
                color: '#DAD3D3',
                lineWidth: [0.5]
            },
        },

    },
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        }
    }
};


export function LineChart() {
    const labels = ['1', '2', '3', '4', '5', '6'];
    const { darkMode } = useContext(ThemeContext);
    const [data, setData] = useState({
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: [10, 12, 35, 47, 49, 56],
                borderColor: '#19F9FA',
                backgroundColor: 'transparent',
            }
        ],
    })

    useEffect(() => {
        console.log(darkMode)
        setData({
            labels,
            datasets: [
                {
                    label: 'Dataset 1',
                    data: [10, 12, 35, 47, 49, 56],
                    borderColor: darkMode ? '#19F9FA' : '#44278F',
                    backgroundColor: 'transparent',
                }
            ],
        })
    }, [darkMode])
    return <Line options={options} data={data} />;
}
